.flexContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: 30px;
}

.choiceContainer {

}

ion-checkbox {
    --size: 30px;
}

.choiceTitle {
    text-align: center;
}